export const baseRoute = '';

export const AppRoutes = {
  invoices: {
    path: 'invoices/',
    name: 'Invoices',
  },
  invoiceCreation: {
    path: 'invoices/new',
    name: 'InvoiceCreation',
  },
  invoice: {
    path: 'invoices/:id?',
    name: 'Invoice',
    props: true,
  },
  requests: {
    path: 'requests/:id?',
    name: 'Requests',
  },
  request: {
    path: 'requests/:id',
    name: 'Request',
  },
  products: {
    path: 'products/',
    name: 'Products',
  },
  product: {
    path: 'products/:id?',
    name: 'Product',
    props: true,
  },
  transfer: {
    path: 'boxes/transfer',
    name: 'Transfer',
  },
  barcodes: {
    path: 'boxes/barcodes',
    name: 'Barcodes',
  },
  complaints: {
    path: 'complaints',
    name: 'Complaints',
  },
  reservations: {
    path: 'reservations',
    name: 'Reservations',
  },
  reservation: {
    path: 'reservations/:id',
    name: 'Reservation',
  },
  administration: {
    path: 'administration',
    name: 'Administration',
  },
  login: {
    path: '/login',
    name: 'Login',
  },
  notAuthorized: {
    path: '/not-authorized',
    name: 'NotAuthorized',
  },
  error: {
    path: '/error/:status',
    name: 'ErrorPage',
    props: true,
  },
};

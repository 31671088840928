import { clone } from '@/utils/collection';

export const AppEvents = {
  auth: {
    login: 'auth/login',
    loginAttempt: 'auth/login-attempt',
    loginFailure: 'auth/login-failure',
  },
  modals: {
    invoice: {
      open: 'modals.invoice.open',
      close: 'modals.invoice.close',
    },
    sku: {
      open: 'modals.sku.open',
      close: 'modals.sku.close',
    },
    error: {
      open: 'modals.error.open',
      close: 'modals.error.close',
    },
    request: {
      open: 'modals.request.open',
      close: 'modals.request.close',
    },
    invoiceCreation: {
      open: 'modals.invoiceCreation.open',
      close: 'modals.invoiceCreation.close',
    },
    requestCreation: {
      open: 'modals.requestCreation.open',
      close: 'modals.requestCreation.close',
      retry: 'modals.requestCreqtion.retry',
    },
    generateBarcode: {
      update: 'modals.generateBarcode.update',
    },
    boxTransferErrors: {
      open: 'modals.boxTransferErrors.open',
      close: 'modals.boxTransferErrors.close',
    },
    hide: 'modals.hide',
    show: 'modals.show',
  },
  pages: {
    invoiceCreation: {
      open: 'pages.invoiceCreation.open',
      close: 'pages.invoiceCreation.close',
    },
    invoice: {
      open: 'pages.invoice.open',
      close: 'pages.invoice.close',
      createRequest: 'pages.invoice.createRequest',
    },
    request: {
      openComments: 'pages.request.openComments',
      close: 'pages.request.close',
    },
    sku: {
      open: 'pages.sku.open',
      close: 'pages.sku.close',
    },
    error: {
      open: 'pages.error.open',
    },
    clear: 'pages.clear',
  },
  barcode: {
    emit: 'barcode.emit',
  },
  sku: {
    update: 'sku.update',
    updateHistory: 'sku.updateHistory',
    defect: 'sku.defect',
  },
  history: {
    load: 'history.load',
    update: 'history.update',
  },
  table: {
    toggle: 'table.toggle',
    open: 'table.open',
  },
  issueEditorModal: {
    open: 'issueEditorModal.open',
    close: 'issueEditorModal.close',
  },
};

export type TPageableEntity<T> = {
  page: number;
  hasMore: boolean;
  totalCount: number;
  items: T[];
  entries: Record<number, T>;
  loading: boolean;
  error: unknown | null;
};

export const ENTITY_INITIAL_STATE: TPageableEntity<any> = {
  page: 0,
  hasMore: true,
  totalCount: 0,
  items: [],
  entries: {},
  loading: false,
  error: null,
};

export const PAGE_SIZE = 20;

export const DEBOUNCE_DELAY = 500;

export const initEntityState = <TVal>(): TPageableEntity<TVal> =>
  clone(ENTITY_INITIAL_STATE);

export const initEntityStateFromKeys = <TVal, TKey extends PropertyKey>(
  keys: Readonly<Array<TKey>>,
) =>
  Object.fromEntries(keys.map((k) => [k, initEntityState()])) as Record<
    TKey,
    TPageableEntity<TVal>
  >;
